import React from "react";
import { Routes, Route } from "react-router-dom";
import Home from "./Home";
import DynamicPage from "./DynamicPage";
import SandBox from "./SandBox";
import SandBoxBee from "./SandBoxBee";
import NoMatch from "./NoMatch";
import { IntlProvider } from "react-intl";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import axios from "axios";
import Spanish from "../lang/es.json";
import English from "../lang/en.json";
import Login from "./Login";
import SignupForm from "./SignupForm";
import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";

axios.defaults.withCredentials = true;

const locales = ["es", "es-ar", "es-es"];
let locale;
let lang;

const App = () => {
  locale = navigator.language.toLowerCase();
  if (locales.includes(locale)) {
    lang = Spanish;
  } else {
    lang = English;
  }

  function updateAppState(object) {
    this.setState(object);
  }

  function getUser() {
    return true;
  }

  return (
    <IntlProvider locale={locale} messages={lang}>
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route exact path="/dynamic" element={<DynamicPage />} />
          <Route exact path="/sandbox" element={<SandBox />} />
          <Route exact path="/sandboxbee" element={<SandBoxBee />} />
          <Route exact path="/login" element={<Login />} />
          <Route exact path="/signup" element={<SignupForm />} />
          <Route element={<NoMatch />} />
        </Routes>
      </LocalizationProvider>
    </IntlProvider>
  );
};

export default App;
