import React, { useState, useEffect, useContext } from "react";
import { SandBoxContext } from "./SandBoxContext"; // You need to create this context
import Styles from "../../public/assets/css/main.css";

function Bee({ index, user, users }) {
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const { setUsers } = useContext(SandBoxContext); // Use the context
  const boxWidth = 800; // Adjust this based on your box dimensions
  const boxHeight = 600; // Adjust this based on your box dimensions

  // Function to detect collision
  const checkCollision = (bee1, bee2) => {
    const size = 4; // Example size, adjust as needed
    return Math.abs(bee1.x - bee2.x) < size && Math.abs(bee1.y - bee2.y) < size;
  };

  useEffect(() => {
    const interval = setInterval(() => {
      setPosition((prevPosition) => {
        let newX = prevPosition.x + user.deltaX;
        let newY = prevPosition.y + user.deltaY;
        if (newX < 0 || newX > boxWidth) {
          user.deltaX = -user.deltaX;
          newX = newX < 0 ? 0 : boxWidth;
        }
        if (newY < 0 || newY > boxHeight) {
          user.deltaY = -user.deltaY;
          newY = newY < 0 ? 0 : boxHeight;
        }

        let preProcUsers = [...users];
        preProcUsers[index].x = newX;
        preProcUsers[index].y = newY;

        users.forEach((otherUser, otherIndex) => {
          if (index !== otherIndex) {
            const otherBeePosition = { x: otherUser.x, y: otherUser.y };
            if (checkCollision({ x: newX, y: newY }, otherBeePosition)) {
              preProcUsers[index].deltaX = -user.deltaX;
              preProcUsers[index].deltaY = -user.deltaY;
            }
          }
        });
        setUsers(preProcUsers);
        return {
          ...prevPosition,
          x: newX,
          y: newY,
        };
      });
    }, 500 / user.deltaX);

    return () => clearInterval(interval);
  }, [user, users, setUsers]);

  return (
    <div
      className={Styles["Bee" + user.id]}
      style={{ left: `${position.x}px`, bottom: `${position.y}px` }}
    />
  );
}

export default Bee;
