import React, { createContext, useState } from 'react';

// Create a context for the sandbox
export const SandBoxContext = createContext(null);

// This is a component that provides the SandBoxContext to its children
export const SandBoxProvider = ({ children }) => {
  const [users, setUsers] = useState([]);

  // The value passed to the provider includes the users array and the setUsers function
  const contextValue = {
    users,
    setUsers,
  };

  return (
    <SandBoxContext.Provider value={contextValue}>
      {children}
    </SandBoxContext.Provider>
  );
};
