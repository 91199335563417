import React, { useState, useEffect } from "react";
import { SandBoxProvider } from "./SandBoxContext";
import Bee from "./Bee";
import Styles from "../../public/assets/css/main.css";

// Function to generate a random number between min and max (inclusive)
function getRandomNumber(min, max) {
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

// Function to generate random users
function generateRandomUsers(N, bees) {
  const randomUsers = [];
  for (let i = 0; i < N; i++) {
    const randomBee = bees[Math.floor(Math.random() * bees.length)];
    const deltaX = getRandomNumber(-5, 15);
    const deltaY = getRandomNumber(-5, 15);
    const x = getRandomNumber(0, 600);
    const y = getRandomNumber(0, 600);

    randomUsers.push({
      ...randomBee,
      deltaX,
      deltaY,
      x,
      y,
    });
  }
  return randomUsers;
}

function SandBoxBee() {
  const [users, setUsers] = useState([]);

  useEffect(() => {
    const bees = [
      { id: "1", name: "Alice", deltaX: 5, deltaY: 5, pulse: 3, x: 0, y: 0 },
      { id: "2", name: "Bob", deltaX: 15, deltaY: 8, pulse: 2, x: 0, y: 0 },
      { id: "3", name: "Charlie", deltaX: 5, deltaY: 9, pulse: 1, x: 0, y: 0 },
      { id: "4", name: "David", deltaX: 25, deltaY: 10, pulse: 2, x: 0, y: 0 },
      { id: "5", name: "Raul", deltaX: 45, deltaY: 40, pulse: 2, x: 0, y: 0 },
    ];
    const randomUsers = generateRandomUsers(50, bees);
    setUsers(randomUsers);
  }, []);

  return (
    <SandBoxProvider>
      <div className={Styles.SandBoxBeeContainer}>
        {users.map((user, index) => (
          <Bee
            key={user.id}
            index={index}
            user={user}
            users={users}
            setUsers={setUsers}
          />
        ))}
      </div>
    </SandBoxProvider>
  );
}

export default SandBoxBee;
