import React, { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import Styles from "../../public/assets/css/main.css";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Link } from "react-router-dom";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import logo from "../detailLogo.png";

const reviewLabTheme = createTheme({
  status: {
    danger: "#e53e3e",
  },
  palette: {
    primary: {
      main: "#6b7a8f",
      darker: "#053e85",
      contrastText: "#ffcc00",
    },
    neutral: {
      main: "#64748B",
      contrastText: "#fff",
    },
  },
});

const drawerWidth = 240;

function UserMenu(props) {
  let navItems;
  navItems = [
    {
      label: "Home",
      link: "/",
      localeId: "app.header.home.label",
      public: true,
    },
    {
      label: "Login",
      link: "/login",
      localeId: "app.header.login.label",
      public: true,
    },
    {
      label: "SandBox",
      link: "/sandbox",
      localeId: "app.header.sandbox.label",
      public: true,
    },
    {
      label: "SandBoxBee",
      link: "/sandboxbee",
      localeId: "app.header.sandboxbee.label",
      public: true,
    },
  ];
  return navItems.map((item) => (
    <Link key={item.label} to={item.link} className={Styles.headerMenu}>
      <FormattedMessage id={item.localeId} defaultMessage={item.label} />
    </Link>
  ));
}

function Preloader(props) {
  return (
    <Backdrop
      sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
      open={false}
    >
      <CircularProgress color="inherit" />
    </Backdrop>
  );
}


const Layout = ({ children }) => {
  useEffect(() => {
    // This code will run after the component has mounted
    // You can perform any side effects or data fetching here

    // For example, you can fetch data from an API
    // axios.get('your-api-endpoint')
    //   .then(response => {
    //     // Handle the data
    //   })
    //   .catch(error => {
    //     // Handle errors
    //   });
    console.log("Holanda");
  }, []); // The empty dependency array means this effect will only run once (equivalent to componentDidMount)

  const navItems = [
    {
      label: "Home",
      link: "/",
      localeId: "app.header.home.label",
      public: true,
    },
    {
      label: "Pricing",
      link: "/pricing",
      localeId: "app.header.pricing.label",
      public: true,
    },
    {
      label: "Login",
      link: "/login",
      localeId: "app.header.login.label",
      public: true,
    },
  ];

  const [mobileOpen, setMobileOpen] = useState(false);
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const container = undefined;

  const handleClose = () => {
    setMobileOpen(false);
  };
  
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleLink = (location) => {
    window.location.href = location;
    this.handleClose();
  };
  
  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: "center" }}>
      <img src={logo} className="App-logo mrkPT1em" alt="logo" style={{"width":"40px"}} />
      <Typography variant="h6" sx={{ my: 2 }}>
        Chatsense
      </Typography>
      <Divider />
      <List className={Styles.drawerListMenu}>
        <UserMenu drawer={true} />
      </List>
    </Box>
  );

  return (
    <ThemeProvider theme={reviewLabTheme}>
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        <Preloader />
        <AppBar component="nav" color="primary" sx={{ height: "64px" }}>
          <Toolbar sx={{ height: "64px" }}>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              sx={{ mr: 2, display: { sm: "none" } }}
            >
              <MenuIcon />
            </IconButton>
            <Link to={"/"}>
              <img src={logo} className={Styles.AppLogo} alt="logo" />
            </Link>
            <Typography
              variant="h6"
              component="div"
              sx={{ flexGrow: 1, display: { xs: "block", sm: "block" } }}
            >
              Chatsense
            </Typography>
            <Box sx={{ display: { xs: "none", sm: "block" } }}>
              <UserMenu />
            </Box>
          </Toolbar>
        </AppBar>
        <Box component="nav">
          <Drawer
            container={container}
            variant="temporary"
            open={mobileOpen}
            onClose={handleDrawerToggle}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
            sx={{
              display: { xs: "block", sm: "none" },
              "& .MuiDrawer-paper": {
                boxSizing: "border-box",
                width: drawerWidth,
              },
            }}
          >
            {drawer}
          </Drawer>
        </Box>
        <Container
          maxWidth={false}
          disableGutters={false}
          className={Styles.mainContainer}
        >
          <Box component="main">{children}</Box>
        </Container>
      </Box>
    </ThemeProvider>
  );
};

export default Layout;
