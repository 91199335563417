import React, { useState, useEffect } from "react";
import Layout from "./Layout";
import Styles from "../../public/assets/css/main.css";

function SandBox() {
  const [direction, setDirection] = useState("down");
  const [playerPosition, setPlayerPosition] = useState({ x: 0, y: 0 });
  const [autoDirection, setAutoDirection] = useState("right");
  const [autoCharacterPosition, setAutoCharacterPosition] = useState({
    x: 5,
    y: 0,
  });

  const map = [
    [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
    [0, 1, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
    [0, 1, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 0, 0, 0, 0],
    [0, 1, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 0, 0, 0, 0],
    [0, 1, 0, 0, 0, 0, 1, 1, 1, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0],
    [0, 1, 0, 0, 0, 0, 1, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0],
    [0, 1, 1, 1, 1, 1, 1, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0],
    [0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0],
    [0, 0, 0, 1, 1, 1, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
    [0, 0, 0, 0, 0, 1, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
    [0, 0, 0, 0, 0, 1, 0, 0, 0, 1, 0, 0, 0, 0, 0, 0, 0, 0, 0],
    [0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 0, 0, 0, 0, 0, 0, 0, 0, 0],
    [0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 0, 0, 0, 0, 0, 0, 0, 0, 0],
    [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
  ];

  const handleKeyPress = (event) => {
    const newPosition = { ...playerPosition };

    switch (event.key) {
      case "ArrowUp":
        setDirection("up");
        newPosition.y -= 1;
        break;
      case "ArrowDown":
        setDirection("down");
        newPosition.y += 1;
        break;
      case "ArrowLeft":
        setDirection("left");
        newPosition.x -= 1;
        break;
      case "ArrowRight":
        setDirection("right");
        newPosition.x += 1;
        break;
      default:
        break;
    }

    if (
      newPosition.x >= 0 &&
      newPosition.x < map[0].length &&
      newPosition.y >= 0 &&
      newPosition.y < map.length &&
      map[newPosition.y][newPosition.x] !== 1
    ) {
      setPlayerPosition(newPosition);
    }
  };

  const updateAutoCharacterPosition = () => {
    setAutoDirection((prevDirection) => {
      console.log(prevDirection);
      let deltaX;
      let deltaY;

      switch (prevDirection) {
        case "up":
          deltaX = 0;
          deltaY = -1;
          break;
        case "down":
          deltaX = 0;
          deltaY = 1;
          break;
        case "left":
          deltaX = -1;
          deltaY = 0;
          break;
        case "right":
          deltaX = 1;
          deltaY = 0;
          break;
        default:
          break;
      }

      let newAutoPosition = {
        x: (autoCharacterPosition.x += deltaX),
        y: (autoCharacterPosition.y += deltaY),
      };

      // Ensure the new position is within the bounds and not on an obstacle
      if (
        newAutoPosition.x >= 0 &&
        newAutoPosition.x < map[0].length &&
        newAutoPosition.y >= 0 &&
        newAutoPosition.y < map.length &&
        map[newAutoPosition.y][newAutoPosition.x] !== 1
      ) {
        setAutoCharacterPosition(newAutoPosition);
        return prevDirection; // Maintain the current direction
      } else {
        switch (prevDirection) {
          case "up":
            return "right";
          case "down":
            return "left";
          case "left":
            return "up";
          case "right":
            return "down";
          default:
            return prevDirection;
        }
      }
    });
  };

  useEffect(() => {
    const intervalId = setInterval(updateAutoCharacterPosition, 250);
    return () => clearInterval(intervalId);
  }, []);

  return (
    <Layout>
      <div className={Styles.mapa} tabIndex="0" onKeyDown={handleKeyPress}>
        {map.map((row, rowIndex) => (
          <div key={rowIndex} style={{ display: "flex" }}>
            {row.map((cell, colIndex) => (
              <div
                key={colIndex}
                style={{
                  width: "64px",
                  height: "64px",
                  backgroundColor: cell === 1 ? "black" : "white",
                  // border: "1px solid black",
                  boxSizing: "content-box",
                  position: "relative",
                }}
              >
                {playerPosition.x === colIndex &&
                  playerPosition.y === rowIndex && (
                    <div
                      className={Styles.Character + " " + Styles[direction]}
                    />
                  )}
              </div>
            ))}
          </div>
        ))}
        {autoCharacterPosition && (
          <div
            className={Styles.autoCharacter + " " + Styles[autoDirection]}
            style={{
              position: "absolute",
              top: autoCharacterPosition.y * 64 + "px",
              left: autoCharacterPosition.x * 64 + "px",
            }}
          />
        )}
      </div>
    </Layout>
  );
}

export default SandBox;
