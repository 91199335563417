import React from "react";
import { Link } from "react-router-dom";
import Container from "@mui/material/Container";
import Layout from "./Layout";
import Grid from "@mui/material/Grid";
import Styles from "../../public/assets/css/main.css";
import logo from "../detailLogo.png";

const Home = () => {
  return (
    <Layout>
      <Container maxWidth={false} disableGutters>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={1}>
            &nbsp;
          </Grid>
          <Grid item xs={12} sm={10} sx={{ textAlign: "center" }}>
            <img src={logo} className={Styles.AppLogoHome} alt="logo" />
          </Grid>
          <Grid item xs={12} sm={1}>
            &nbsp;
          </Grid>
          <Grid item xs={12} sm={12} sx={{ textAlign: "center" }}>
            <Link to={"mailto:mborches@chatsense.pro"}>Contact</Link>
          </Grid>
        </Grid>
      </Container>
    </Layout>
  );
};

export default Home;
