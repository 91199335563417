import React from "react";
import SigninForm from "./SigninForm";
import Layout from "./Layout";

const Login = () => {
  return (
    <Layout>
      <SigninForm />
    </Layout>
  );
};

export default Login;